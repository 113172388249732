<template>
  <v-app>
    <!-- <v-alert
      dense
      outlined
      type="info"
    >
      Link G-drive File <strong>Syarat Ujian PKL</strong>

      <span class="d-block text-dark">Lembar persetujuan ujian PKL, kartu bimbingan PKL (PDF), logbook PKL (PDF)</span>
    </v-alert> -->

    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="primary">Link G-drive File <strong>Syarat Ujian PKL</strong> <i class="fas fa-solid fa-angle-down"></i></b-button>
      </b-card-header>
      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body class="bg-secondary p-2">
          <ul>
            <li>Lembar persetujuan ujian PKL</li>
            <li>Kartu Bimbingan PKL (PDF)</li>
            <li>Logbook (PKL)</li>
          </ul>
        </b-card-body>
      </b-collapse>
    </b-card>

          <!-- <b-form-group
            id="input-group-teacher-id"
            label="Dosen Pembimbing PKL:"
            label-for="input-teacher-id"
          >
            <b-form-input
              id="input-teacher-id"
              v-model="display.teacher_name"
              placeholder="Pilih Dosen Pembimbing PKL"
              readonly
              @click="$bvModal.show('modal-teacher')"
            ></b-form-input>
            <small class="text-danger">{{ error.teacher_id }}</small>
          </b-form-group> -->

           <b-form-group
            id="input-group-project-title"
            label="Judul Laporan PKL:"
            label-for="input-project-title"
          >
            <b-form-input
              id="input-project-title"
              v-model="form.project_title"
              placeholder="Judul Laporan PKL"
            ></b-form-input>
            <small class="text-danger">{{ error.project_title }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-agency"
            label="Nama Instansi PKL:"
            label-for="input-agency"
          >
            <b-form-input
              id="input-agency"
              v-model="form.agency"
              placeholder="Nama Instansi PKL"
            ></b-form-input>
            <small class="text-danger">{{ error.agency }}</small>
          </b-form-group>

          <!-- <b-form-group id="input-group-exam-date">
            <label for="input-exam-date">Tanggal Ujian: </label>
            <v-menu
              v-model="menuDate1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-exam-date"
                  v-model="form.exam_date"
                  placeholder="Tanggal Ujian:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.exam_date"
                @input="menuDate1 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.exam_date }}</small>
          </b-form-group> -->

          <b-form-group
            id="input-group-pkl-report-ullr"
            label="Link G-Drive File Laporan PKL:"
            label-for="input-pkl-report-ullr"
          >
            <b-form-input
              id="input-pkl-report-ullr"
              v-model="form.pkl_report_url"
              placeholder="Link G-Drive File Laporan PKL"
            ></b-form-input>
            <small class="text-danger">{{ error.pkl_report_url }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-pkl-exam-condition-url"
            label="Link G-Drive File Syarat Ujian PKL:"
            label-for="input-pkl-exam-condition-url"
          >
            <b-form-input
              id="input-pkl-exam-condition-url"
              v-model="form.pkl_exam_condition_url"
              placeholder="Link G-Drive File Syarat Ujian PKL"
            ></b-form-input>
            <small class="text-danger">{{ error.pkl_exam_condition_url }}</small>
          </b-form-group>

          <!-- <b-form-group id="input-group-description">
            <label for="input-description">Keterangan: <em class="text-muted">opsional</em></label>
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Keterangan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group> -->
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/work-practice-exam-registrations/list')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
    <ModalTeacher
      id="modal-teacher"
      purpose="modal-teacher"
      @chooseData="chooseDataTeacher"
      :filterProps="{ role_id: 1 }"
      title="Pilih Dosen Pembimbing PKL"
    />
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalTeacher from "@/view/components/general/ModalUser2.vue";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
  },
  components: {
    ModalTeacher,
  },
  data() {
    return {
      formData: new FormData(),
      error: {
        date: "",
        description: "",
        teacher_id: "",
        student_id: "",
        agency: "",
        project_title: "",
        exam_date: "",
        pkl_report_url: "",
        pkl_exam_condition_url: "",
      },
      menuDate1: false,
      menuDate2: false,
      display: {
        teacher_name: "",
      },
    };
  },
  methods: {
    chooseDataTeacher(value) {
      this.$bvModal.hide("modal-teacher");
      this.form.teacher_id = value.id;
      this.display.teacher_name = value.name;
    },
    async formOnsubmit() {
      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push("/work-practice-exam-registrations");
      }
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.display.teacher_name = this.form.teacher_name;
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 230px !important;
  height: 230px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>